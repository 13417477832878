import React from 'react';
import './SectionFaq.scss';
import './mobile/index.scss';
import {
  Container, Grid, Accordion as MuiExpansionPanel, AccordionSummary as MuiExpansionPanelSummary, AccordionDetails as MuiExpansionPanelDetails,
} from '@material-ui/core';
import { withTranslation } from '@i18n';
import { connect } from 'react-redux';
import { Btn, Polygon } from '../index';
import { isPagePath } from '../../helpers';

type Props = {
    language?: string;
    location?: string;
    t?: any;
    windowWidth?: any;
    textArr?: any;
    title?: string
};
const SectionFaq = (props: Props) => {
  const [expanded, setExpanded] = React.useState(false);
  const {
    language, windowWidth, t, location, textArr, title,
  } = props;
  const expansionChange = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : false);
  };
  const darkTheme = isPagePath(location, ['/:lang/joint-participation-binance', '/:lang/binance']);
  function getBtnThemeClassName() {
    if (darkTheme) return 'dark-yellow';
    return 'gray';
  }
  function getThemeClassName() {
    if (darkTheme) return 'section-faq--dark';
    return 'section-faq--white';
  }
  const arr = textArr || [
    ['Как это работает?', 'Вы арендуете вычислительную мощность. Для добычи 1 BTC нужно, чтобы майнер был настроен и работал. Электричество, работа инженеров, расходные материалы: все это включено в плату за обслуживание. Контракт работает только при оплаченном обслуживании. Сделать это можно при покупке и в личном кабинете. Вам остается лишь не переживать и получать свой ежедневный доход в BTC.'],
    ['От чего зависит мой доход?',
      <>
        {t('От вас')}
        {language === 'ru' ? ':' : ''}
        {t(
          'от хешрейта (TH/s) вашего контракта/майнера и срока их работы.',
        )}
        <br />
        {t('От нас')}
        {language === 'ru' ? ':' : ''}
        {t(
          'насколько дешево мы получаем электричество и как хорошо настроено оборудование.',
        )}
        <br />
        {t('От высших сил')}
        {language === 'ru' ? ':' : ''}
        {t(
          'насколько сложно добыть 1 монетку и от ее курса прямо сейчас.',
        )}
      </>,
    ],
    ['На чем зарабатывает CryptoUniverse?', 'Полученные от продаж контрактов деньги мы используем для строительства новых дата-центров и быстрого роста компании. Наша цель - стать компанией №1 в мире. Покупая контракт, вы получаете доход в растущей в цене криптовалюте и делаете криптобудущее ближе уже сегодня. А плата за обслуживание берется за то, что вам не нужно следить за майнером и условиями его работы, оплачивать электроэнергию и аренду помещения, все это мы берем на себя.'],
    ['Чем облачный майнинг криптовалюты отличается от покупки майнера?', 'Контракт облачного майнинга требует вложений меньше, чем майнер, но и приносит меньше прибыли. Майнер дороже, но прибыльнее в долгосрочной перспективе. Для знакомства с миром майнинга и биткоинов лучше подойдет контракт облачного майнинга, для получения максимальной прибыли лучше купить майнер.'],
    ['Как попробовать клауд майнинг, не вкладывая реальных денег?', 'Подписаться на нас в социальных сетях, там мы периодически раздаем вычислительные мощности. Еще можно оставить электронную почту в форме ниже и читать наши письма.'],
  ];
  const buttonContactUs = (
    // @ts-ignore
    <div style={windowWidth < 1024 ? { marginTop: '24px' } : null}>
      <div className="section-faq__description">
        {t('Не нашли ответ на свой вопрос?')}
      </div>
      <div className="section-faq__button ">
        <Btn
          component="a"
          variant="outlined"
          color={getBtnThemeClassName()}
          border={{ width: 2, color: darkTheme ? '#F0B90B' : '#03349e' }}
          flex
          justifyContent="space-between"
          href='https://cryptouniverse.freshdesk.com/support/tickets/new'
        >
          {t('Напишите нам')}
          <i className="fa fa-caret-right  ml2" />
        </Btn>
      </div>
    </div>
  );
  return (
    <div className={`section-faq ${getThemeClassName()}`} id="sectionFaq">
      <Container>
        <Grid container spacing={3}>
          <Grid
            container
            item
            md={4}
            sm={12}
            xs={12}
            suppressHydrationWarning
            className="linkBlock"
          >
            <h3 className="section-faq__title">
              {title ? t(title) : t('Ничего не скрываем')}
            </h3>
            {windowWidth >= 1024 && buttonContactUs}
          </Grid>
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            suppressHydrationWarning
          >
            {
              arr.map((elem: any, n: any) => (
                <MuiExpansionPanel
                  key={`Expression_Panel${n + 1}`}
                  elevation={0}
                  className="section-faq__panel"
                  expanded={String(expanded) === `panel${n + 1}`}
                  onChange={expansionChange(`panel${n + 1}`)}
                  itemScope
                  itemType="http://schema.org/Question"
                >
                  <MuiExpansionPanelSummary className="section-faq__summary">
                    <span itemProp="name">{t(elem[0])}</span>
                    <i className="fal fa-plus" />
                  </MuiExpansionPanelSummary>
                  <MuiExpansionPanelDetails
                    className="section-faq__details"
                    itemProp="suggestedAnswer"
                    itemScope
                    itemType="http://schema.org/Answer"
                  >
                    {darkTheme ? (
                      <Polygon
                        cornerRadius={32}
                        cornerPosition="rightTop"
                        bgColor="#F4F4F7"
                        className="dark-theme__binance"
                      >
                        {typeof elem[1] === 'object' ? elem[1] : t(elem[1])}
                      </Polygon>
                    ) : (typeof elem[1] === 'object' ? elem[1] : t(elem[1]))}
                  </MuiExpansionPanelDetails>
                </MuiExpansionPanel>
              ))
            }
            {// @ts-ignore
            windowWidth < 1024 && buttonContactUs
}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
function mapStateToProps(state: any) {
  const { language, windowWidth, location } = state.info;
  return {
    language,
    windowWidth,
    location,
  };
}
export default withTranslation('common')(connect(mapStateToProps)(React.memo(SectionFaq)));
